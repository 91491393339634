<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="{...value,type: simType,slot: simSlot }"/>
  </div>
</template>
<script setup>
import {computed, defineEmits} from "vue"

import fieldsRows from '@/commonComponents/fieldsRows.vue';

// eslint-disable-next-line no-undef
const props = defineProps(['field', 'fieldAttributes', 'result', 'value', 'valueLine']);
const emit = defineEmits(['input'])


const simType = computed(() => {
  let simType = '';
  if (props?.value?.type) {
    simType = props.value.type;
  }
  return simType;
});

const simSlot = computed(() => {
  let simSlot = '';
  if (props?.value?.slot) {
    simSlot = props.value.slot;
  }
  return simSlot;
});

const updateFieldValue = (index, value) => {
  let localValue = {...props.value};
  localValue[index] = value;
  emit('input', localValue);
  if(value==='local' && localValue?.tagSyntax){
    delete localValue['tagSyntax'];
  }else if(value==='remote' && localValue?.slot){
    delete localValue['slot'];
  }
}

const templateContent = computed(() => {
  let templateContent = {
    fields: {
      pingLine: {
        type: 'line',
        fields: {
          type: {
            type: 'select',
            name: 'SIM Type',
            associatedOption: ['local', 'remote'],
            setEmpty: false,
            ignoreEmptyAdd: true,
            default: 'local',
            colAttrs: {style: 'max-width: 170px;'},
          },
        tagSyntax: {
          type: 'text',
          colAttrs: {style: 'max-width: 382px;'},
          inputFieldFormatter: 'explorerSimInsertTagSyntax',
          },
          /*      tagSyntax: {
            type: 'text',
            name: 'Tag Syntax',
            colAttrs: {style: 'max-width: 230px;'},
            fieldAttrInput: { class:'required' },
          },
            fieldAttrInput: { class:'required checkTagSyntax' },
          },*/
        },
      },
    },
    disableAllInputs: props.result.disableAllInputs,
    contentID: props.result.contentID,
  };
  if (props.valueLine.parameters.type === 'local') {
    if (templateContent?.fields?.pingLine?.fields?.tagSyntax) {
      delete templateContent.fields.pingLine.fields.tagSyntax;
    }
    templateContent.fields.pingLine.fields.slot = {
      type: 'select',
      name: 'SIM Slot',
      associatedOption: [
        [1, '1'],
        [2, '2']
      ],
      ignoreEmptyAdd: true,
      setEmpty: false,
      colAttrs: {
        class: 'slot',
        style: 'max-width: 170px;',
      },
      fieldAttrInput: { class:'required' },
    };
  }
  return templateContent;
});

</script>